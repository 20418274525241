.spinner-wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: white;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    z-index: 999999999;

    svg {
        opacity: 1;
    }
}


.dot {
    height: 17px;
    width: 17px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px; 

    &.dot-green{
        background-color: green;
    }
    
    &.dot-red{
        background-color: red;
    }
}