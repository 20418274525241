.langCard {
    width: 100px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;

    &:hover{
        background: #e1e1e1;
    }
}
