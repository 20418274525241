.imgHeader {
    width: 100%;
    height: 200px;
    object-fit: cover;
}


.titleContainer{
    background-color: black;
    width: 100%;
    color: white; 
}