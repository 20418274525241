.resultContainer {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;

    .rowSurvey {
        display: grid;
        margin-bottom: 3px;
        grid-template-columns: 3fr 1fr;

        .surveyName {
            color: black;
        }

        .answer {
            color: black;
        }
    }
}
